.ql-editor {
	min-height: 120px;
	height: auto;
	resize: vertical;
	overflow-y: auto;
}
.ql-editor[style*="height"] {
  max-height: unset;
}
.ql-tooltip{
	position:absolute;
	margin-left: 20%;
}
.ql-editor::-webkit-resizer {
  background-image: url('../../assets/images/drag.png');
  background-size: 86%;
	background-position: center;
	background-repeat: no-repeat;
}
.ql-container {
	height: auto;
}
