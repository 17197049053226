body {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  font-size: 0.75rem !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body, #root, main {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 769px) {
  #root {
    overflow: auto;
  }
}
