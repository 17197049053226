.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.MuiListItemIcon-root {
  min-width: 40px !important;
}
.App-link {
  color: #09d3ac;
}
.header-nav {
  padding: 0.75rem;
}
.header-nav > a {
  color: #fff !important;
  text-decoration: none;
}
.active {
  border-bottom: 2px solid #c51162;
}
.MuiButton-root {
  text-transform: none !important;
}
.MuiToolbar-regular {
  min-height: 48px !important;
}
.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  padding-top: 0 !important;
  padding-left: 8px;
}
.MuiFilledInput-input {
  padding: 17px 12px 10px !important;
}
.MuiTextField-root {
  width: 100%;
}
.MuiTooltip-tooltip {
  background: #ffffff !important;
  color: #000000 !important;
  border: 1px #cccccc solid !important;
  font-size: 13px !important;
  line-height: 22px !important;
}
.MuiTooltip-tooltip p {
  margin-bottom: 0px;
  margin-left: 0px;
}
.MuiExpansionPanelSummary-root {
  padding: 0 20px 0 0px !important;
}
.table thead th {
  font-size: 0.75rem;
}
.MuiTypography-h6 {
  font-size: 1.25em !important;
}
.panel1a-header {
  min-height: 45px !important;
}
.MuiExpansionPanel-root.Mui-expanded {
  margin: 0px 0 !important;
}
@media (min-width: 600px) {
  .makeStyles-drawerHeader-170 {
    min-height: 48px !important;
  }
}
.MuiDialogTitle-root {
  border-bottom: 1px solid #efefef;
}
.list-header {
  font-size: 12px;
  min-height: 23px;
  margin-bottom: 3px;
  padding: 3px;
  box-sizing: border-box;
  background-color: #fff;
}

.category-header h4{
  font-size: 0.875em;
    text-transform: uppercase;
    font-weight: bold;
    /* inline-size: 14px; */
    line-height: 15px;
}
.info {
  width: 20px;
  height: 20px;
  background-color: white;
  bottom: 20%;
}

.cardDetails-group{
  margin-bottom: 8px;
  position: relative;
}
.cardDetails-pillar--main{
  width: 61.5%;
  padding-right: 30px;
}

@media (min-width: 40em){
.cardDetails .cardDetails-scroll > .cardDetails-pillar {
    margin: 0;
    float: left;
}
}
.MuiDialog-paper{
  width: 60%;

}

.gridBorder{
  border-right: 1px solid #e0e0e0
}
.cardContainerLabel {
    font-size: 0.8125rem;
    font-weight: bolder;
    display: block;
    margin: -1px 0 4px 2px;
    padding: 0;
    color: #444;
}
.sortField .MuiSelect-selectMenu {
  padding-right: 40px !important;
}

.generaltype-header {
   color: #fff;
  font-weight: bold;
  padding: 2px 7px 6px 4px;
  line-height: 1;
  /* background-color:rgba(0, 79, 97, 0.7); */
}

.header-truncate{
  max-width: 100%;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
.svgIcon--calendar.is-warning {
  fill: #fac80a;
}

svg:not(:root) {
  overflow: hidden;
}
.svgIcon {
  display: inline-block;
  color: #fff;
  height: inherit;
  width: inherit;
}
.pull-right{
  float: right;
}

a.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit:hover {
  color: inherit;
}


/* Let's get this party started */
/* Let's get this party started */
:not(.ql-editor)::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
:not(.ql-editor)::-webkit-scrollbar-track:vertical {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
:not(.ql-editor)::-webkit-scrollbar-thumb:vertical {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(161, 159, 159, 0.8);
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
:not(.ql-editor)::-webkit-scrollbar-thumb:window-inactive {
background: rgba(161, 159, 159, 0.8);
}
/* Track */
/* ::-webkit-scrollbar-track:vertical {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  display: none;

} */

/* Handle */
 /* ::-webkit-scrollbar-thumb:vertical {
  background: red;
  border-radius: 10px;
  display: none;

} */

 /* ::-webkit-scrollbar-track:horizontal {
  box-shadow: inset 0 0 5px grey;
  border-radius: 1px;


} */

/* Handle */
 /* ::-webkit-scrollbar-thumb:horizontal {

  border-radius: 0px;

} */
:not(.ql-editor)::-webkit-scrollbar-track:horizontal {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
:not(.ql-editor)::-webkit-scrollbar-thumb:horizontal {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(161, 159, 159, 0.8);
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
.lane-wrapper-collapsed{
  display: none;
}
.grid-container{
  background:#dddddd
}
.card__true{
  border: 2px solid blue;
  opacity: 0.5 !important;
}
.note-popover.popover .arrow{
  width: 100% !important;
  margin-left: -21px;
    padding-top: 12px;
    display: block !important;;
}
.note-hint-popover{
  z-index: 10000;
  height: 200px;
  width: 500px;
}
.lane:last-child{
  height: 100%;
}

.comment{
  display: flex;
    flex-direction: column;
    align-items: stretch;
}
div.subcategory-wapper + div.comment{
  background: red !important;
}

.category > div.lane-wrapper > div.comment > div.lane:last-child {
  height: 100%;
}

.subcategory-wrapper > div.comment > div.lane:nth-last-child(-n+1){
  height: 100%;
}
.card-title-with-subcategory{
  width: 110px;
  height: 30px;
  font-size: 10px;
  display: inline-block;
  text-align: left;
  overflow: hidden;
  margin-bottom: 0px;
  padding: 5px;
  padding-right: 0;
}

.cardTabs {
  background-color: #F2F5FA;
  padding: 0px 12px;
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 900px;
  z-index: 1;
}
@media (max-width: 1280px) {
  .cardTabs {
    max-width: 900px;
  }
}
@media (max-width: 960px) {
  .cardTabs {
    max-width: 700px;
  }
}
@media (max-width: 730px) {
  .cardTabs{
    max-width: 100%;
  }
}
@media (max-width: 599.95px) {
  .cardTabs .MuiTabs-scrollButtonsDesktop {
    display: inline-flex;
  }
}
.cardTabs .Mui-selected {
  background-color: #F2F5FA;
}
.cardTabs .MuiTab-root {
  min-width: 0px;
  color: #000;
  font-weight: 700;
  opacity: 1;
  padding: 6px 15px !important;
}
.cardTabs .MuiTab-root:hover {
  background-color: #F2F5FA;
}
.cardTabs .MuiTab-root:focus {
  background-color: #F2F5FA;
}
.cardTabs .MuiTab-root:active {
  background-color: #F2F5FA;
}

.dialogAction {
  position: fixed;
  bottom: 0;
  right: 0;
  background: #EEEEEE;
  width: 100%;
  max-width: 900px;
}
.dialogAction-D {
  position: fixed;
  bottom: 0;
  right: 0;
  background: #EEEEEE;
  width: 100%;
  max-width: 535px;
}
@media (max-width: 1280px) {
  .dialogAction {
    max-width: 900px;
  }
}
@media(min-width: 900px){
  .createDashBtnMob {
    display: none;
  }
}
@media (max-width: 960px) {
  .dialogAction {
    max-width: 700px;
  }
}
@media (max-width: 730px) {
  .dialogAction {
    max-width: 100%;
  }
  .resDialogAction {
    flex-wrap: wrap;
  }
  .dialogAction button {
    margin: 8px !important;
  }
  .zeroRMargin {
    margin-right: 0px !important;
  }
}

.cardHistoryTimeline {
  height: 500px;
  overflow: auto;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.formLabel {
  font-size: 0.8125rem;
  font-weight: normal;
  color: #200E32;
}
.card-form .MuiInputLabel-shrink {
  font-size: 14px;
  font-weight: normal;
  color: #200E32;
}
.card__childcard{
  opacity: 0.5 !important
  /* border: 3px solid #ff6347; */
}
.card__parentcard{
  border: 3px solid #00008b;
}

.favIcon {
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
  display: inline-block;
}

.ticket-desc {
  pointer-events: visible;
    padding: 10px;
    border: 1px solid #efefef;
}

.MuiPopover-root:hover  {
  pointer-events: initial !important;
}

.resMenuIcon {
  display: none !important;
}
.saved-filter-name:hover {
  text-decoration: underline;
}
.saved-filter-name{
  overflow: hidden;
  white-space: nowrap;
   width: 125px;
     text-overflow: ellipsis
}
.cardActions {
  justify-content: flex-end;
}
.profile-height {
  height: 60vh;
  overflow: auto;
}
@media only screen and (max-width: 769px) {
  .loginForm {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 !important;
  }
  .resMenuIcon {
    padding: 0px !important;
    display: block !important;
  }
  .webMenu {
    display: none !important;
  }
  .MuiDialog-paper{
    width: 100%;
  }
  .cardDetailsDrawer {
    z-index: 1299 !important;
  }
  .cardsDialog {
    z-index: 1299 !important;
  }
  .createDashBtnWeb {
    display: none;
  }
  .createDashBtnMob {
    padding: 12px 12px 0px 10px;
    display: flex;
    justify-content: flex-end;
  }
  .actionItems {
    margin: 0px !important;
  }
  .emptyMsg {
    margin: 15px !important;
  }
  .widgetCloseButton {
    margin-left: auto !important;
    margin-top: 0 !important;
  }
  .dialogTitleDash {
    display: flex;
    align-items: center;
  }
  .widgetForm, .inputField {
    width: 100% !important;
  }
  .widgetAddTitle {
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 730px) {
  .resDrawer .MuiDrawer-paper {
    width: 100%;
  }
  .dialogClose {
    display: none !important;
  }
  .historyContainer {
    flex-direction: column-reverse;
  }
  .historyInfo {
    max-width: 100% !important;
  }
  .userIcon {
    display: flex;
    justify-content: center;
  }
  .containerTitle {
    margin-left: 0px !important;
  }
  .inputFields {
    margin-top: 8px !important;
  }
  .selectFormControl {
    min-width: 100% !important;
  }
  .selectInput {
    width: 100% !important;
  }
  .reportTitle h2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .copyalert {
    margin-left: 0px !important;
  }
  .filterContainer {
    width: 100% !important;
    min-height: unset !important;
  }
  .filterActionBtn {
    float: unset !important;
    flex-wrap: wrap;
  }
  .filterActionBtn button {
    margin: 5px;
  }
  .quickFilterBtn {
    margin-right: 5px !important;
  }
  .tabPanelContainer {
    height: calc(100vh - 75px) !important;
  }
  .actionBtn {
    padding: 0px 5px !important;
  }
  .userAccessList {
    font-size: 12px !important;
  }
  .cardTypeCont {
    margin: 10px 0px !important;
  }
  .actionTypeBtn {
    padding: 12px 8px !important;
  }
  .cardHeaderTitle {
    margin-left: 4px !important;
  }
}

@media only screen and (max-width: 600px) {
  .prefSettings {
    flex-direction: row !important;
  }
  .checkboxSettings {
    width: 45%;
  }
  .profileContainer {
    margin-bottom: 65px !important;
    overflow: hidden;
  }
  .notSettings {
    overflow-x: auto;
    width: 100%;
  }
  .searchSection {
    margin: 20px 0px;
    float: unset !important;
  }
  .boardOptions {
    width: 600px;
  }
  .cardActions {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #fff;
    padding: 15px 30px !important;
    justify-content: flex-end;
  }
  .boardNotifyCont {
    min-height: 120px;
    height: 100% !important;
  }
  .dashboardHeader {
    margin-top: 12px !important;
  }
  .dashboardTitle {
    text-align: center;
  }
  .dashboardAction {
    margin-bottom: 12px !important;
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
  }
}
.profile-main {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  border-style: solid;
  border-color: #FFFFFF;
  box-shadow: 0 0 8px 3px #B8B8B8;
  position: relative;
}

.profile-main img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.ql-editor p > img {
 width:700px;
}

.ql-editor {
  word-break: break-word;
}

.profile-edit-icon {
  position: absolute;
  top: 20px;
  right: -12px;
  /* border: 1px solid; */
  border-radius: 50%;
  /* padding: 11px; */
  height: 30px;
  width: 30px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: cornflowerblue;
  box-shadow: 0 0 8px 3px #B8B8B8;
}
#currency-input {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42)
}

.widgetContainer{
  /* background: white; */
  background: rgb(238, 243, 246);
  margin-top: 10px
}
.widgetBody {
  background: rgb(255, 255, 255);
    border-style: solid;
    border-color: rgb(216, 216, 216);
    border-radius: 4px;
    border-width: 1px;
    box-shadow: rgb(176 176 176 / 16%) 0px 2px 4px, rgb(176 176 176 / 23%) 0px 2px 4px;
}
.widgetHeader {
  font-size: 14px;
    height: 30px;
    line-height: 30px;
    display: block;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
    border-bottom: 0.0625rem solid rgba(0,0,0,.1);
}
.widgetButton {
  margin-right: 7px !important;
}
.item, .MyDragHandleClassName{
  /* border: 1px solid; */
}
.modelDialog {
  width: 500px;
}
.widgetFormControl{
    width: 100%;
}
.widgetCloseButton{
    position: absolute !important;
    right: 1px;
    top: 1px;
    color: gray;
}
.widgetNoData{
  display: flex;
  justify-content: center;
  align-items: center;
}
.widgetTitle{
  font-size: 14px !important;
  font-weight: bold !important;
  padding-left: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.widgetSubTitle{
  font-size: 10px !important;

  padding-left: 12px;
  min-width: 250px;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboardTitle {
  padding-left: 12px;
    margin-top: 8px;
    height: 5px;
}
.dashboardHr{
  border: 1px solid #e0e0e0;
}

.hide-overflow {
  overflow: hidden;
}