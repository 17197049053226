body {
  background-color: #ccc;
}

h2 {
  margin: 3px;
  font-size: 16px;
}

h4 {
  margin: 0px;
  font-size: 12px;
}
h6 {
  font-size: 0.75rem;
}

hr {
  margin: 0px;
}

.board {
  text-align: center;
  background-color: #ccc;
  padding: 3px;
  min-height: 100vh;
  transform-origin: 0 0;
  transform: scale(1);
}

.lane-wrapper * {
  will-change: initial !important;
}

.list-header {
  font-size: 12px;
  margin-bottom: 3px;
  padding: 3px;
  box-sizing: border-box;
  background-color: #eee;
}

.list-header-editing {
  font-size: 12px;
  margin-bottom: 3px;
  padding: 3px;
  box-sizing: border-box;
  background-color: white;
}

.category-header {
  position: relative;
}

.lane-header-text {
  margin: 0px;
}

.left {
  text-align: left;
  position: absolute;
}

.left-collapsed {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  writing-mode: horizontal-tb;
}

.center {
  text-align: center;
}

.button-toggle {
  padding: 3px;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
}
.text-overflow {
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden
}

.category {
  float: left;
  background-color: #ccc;
  margin-right: 3px;
  margin-bottom: 3px;

}

.category-collapsed {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  height: 80%;
}

.category-collapsed:not(:last-child) {
  margin-right: 3px;
}

.subcategory-wrapper {
  display: grid;
  height: calc(100% - 26px);
}

.subcategory-wrapper-collapsed {
  display: none;
}

.subcategory {
  background-color: #ccc;
}

.subcategory:not(:last-child) {
  margin-right: 3px;
}
.comment:not(:last-child) {
  margin-right: 3px;
}
.lane-wrapper {
  display: flex;
  flex-direction: column;
}
.lane {
  background-color: #ddd;
}

.lane:not(:last-child) {
  margin-bottom: 3px;
}

.list-wrapper {
  display: flex;
  overflow: auto;
  text-align: center;
}

.card-container {
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  min-width: 270px;

  /* grid-template-columns: repeat(2, 1fr); */
    border-bottom: 100px solid transparent;
}

.card-wrapper {
  width: 120px;
  height: 90px;
  cursor: pointer;
}

.card-container:not(:last-child) {
  margin-right: 5px;
}

.card {
  background-color: lightblue;
  display: flex;
  overflow: visible;
  position: relative;
  width: 120px;
  height: 90px;
  margin: 5px;
  border-radius: 3px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  transform: translate3d(0, 0, 0);
}

.card:not(:last-child) {
  margin-right: 10px;
}

.card-header-id {
  text-align: left;
  font-weight: bold;
}

.card-title {
  width: 110px;
   height: 40px; 
  font-size: 10px;
  display: inline-block;
  text-align: left;
  overflow: hidden;
  margin-bottom: 0px;
  padding:5px;
  padding-right:0;

}

.placeholder {
  flex-grow: 1;
  width: 135px;
  height: 100px;
}

.wide {
  width: 135px;
}

.tooltip-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #fff;
  position: absolute;
  bottom:-5px
}

.tooltip-text {
  padding: 5px;
}

.owner {
  font-size: 10px;
  height: 25px;
  width: 25px;
  background-color: #3f51b5;
  right: -7px;
  top: -5px;
}
.team {
  background-color: #64b5f6;
  font-size: 16px;
  bottom: -5px;
}
.rag {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  position: absolute;
  background-color: white;
  right: -10px;
  bottom: -5px;
}
.tag {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  position: absolute;
  right: -10px;
  bottom: -5px;
}
.priority{
 background-color: #df5c57;
}
.lowpriority {
  background-color: #b9c1c9;
}
.red {
  color: crimson;
}

.amber {
  color: gold;
}

.green {
  color: limegreen;
}

.info {
  width: 20px;
  height: 20px;
  background-color: white;
  bottom: 3px;
}

.info-btn {
  padding: 5px 10px 5px 10px;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
}

.details-modal {
  width: 640px;
  height: 360px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  border-radius: 4px;
  background-color: #fff;
  outline: 0;
}

.details-tab {
  width: 640px;
  height: 360px;
}

.details-header {
  padding: 20px;
}

.details-body {
  padding: 20px;
}

.details-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 20px 0px 20px 0px;
}

.details-col {
  max-height: 200px;
  overflow-y: auto;
}

.details-col:not(:last-child) {
  margin-right: 20px;
}

.details-btn-container {
  display: inline;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.btn-wrapper {
  display: inline;
  position: absolute !important;
  bottom: 10px;
  right: 10px;
}

.details-btn {
  display: inline;
  margin: 10px !important;
}

.sidebar {
  width: 500px;
  padding: 20px;
}

.sidebar-search-results {
  overflow-y: scroll;
  height: 300px;
  padding: 20px;
}

.sidebar-search-lists {
  text-transform: uppercase;
}

.sidebar-btns {
  display: inline;
}

.config-sub-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.config-lane-container {
  display: flex;
  flex-direction: column;
}

.config-lane-container:not(:last-child) {
  margin-right: 20px;
}

.board-nav {
  position: fixed !important;
  top: 57px;
  right: 15px;
}

.edit-btn {
  position: fixed !important;
  top: 60px;
  right: 15px;
}

.creation-modal {
  width: 640px;
  height: 360px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  border-radius: 4px;
  background-color: #fff;
  outline: 0;
}

.creation-header {
  padding: 20px;
}

.creation-body {
  padding: 20px;
}

.creation-footer {
  display: inline;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.template-modal {
  width: 75%;
  height: 75%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  border-radius: 4px;
  background-color: #fff;
  outline: 0;
}

.board-template {
  text-align: center;
  background-color: #ccc;
  padding: 3px;
  margin: 10px;
}

.card-count {
  width: 18px;
  line-height: 18px;
  margin: 2px;
  border-radius: 50%;
  text-align: center;
  color: white;
  background-color: #666;
 }

 .name-alert {
  position: fixed;
  top: 60px;
  right: 150px;
 }
 .cardtype {
  height:20px;
  width:20px;
  border-radius: 3px
}
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding-top:0 !important;
  padding-bottom: 0 !important
}
#outlined-basic-size {
  padding-top:10px !important;
  padding-bottom: 10px !important
}
.dropdown-toggle::after{
  display: none;
}
.pull-right {
  float: right;
}
.badge{
  background-color: #3f51b5;
    right: 15px;
    top: 15px;
}
.text-field{
  width: 80%;
}
.task-heading{
  padding:20px;
  text-align: center;
  background:#efefef;
  border-right: 1px solid #d6d6d6;
  border-left: 1px solid #d6d6d6;
}

.laneStatus {
  display: flex;
  flex-direction: row;
  margin-right: 3px;
}
.MuiPopover-paper {
  overflow-x: auto !important;

}

.MuiPopover-paper {
  overflow: auto !important;
}
.MuiMenuItem-root {
  overflow: initial !important;
}
.laneStatus-todo:before, .laneStatus-todo:after {
  border-bottom: 3px solid #618db4;
}
.laneStatus-doing:before, .laneStatus-doing:after {
  border-bottom: 3px solid#629f67;
}
.laneStatus-done:before, .laneStatus-done:after {
  border-bottom: 3px solid #444;
}
.laneStatus:before, .laneStatus:after{
  content: "";
  flex: 1 1;
  margin: auto;
}
.pull-right {
  float: right;
}
.card-size-icon {
  float: left;
    border: solid 2px #fff;
    border-radius: 13px;
    text-align: center;
    color: #fff;
    line-height: 22px;
    width: auto;
    padding: 0 3px;
    overflow: hidden;
    font-size: 0.875em;
    font-weight: bold;
    background-color: rgb(70, 110, 138);
}
.MuiButtonBase-root {
  outline: none !important;
}
.note-popover {
  display: none;
}
/* .MuiDialog-paperWidthSm {
  max-width: 310px !important;
} */
.error {
  color: #df5c57;
}
.MuiTab-textColorInherit.Mui-selected {
background-color: #efefef;
font-weight: 700;
}
.unlink-icon {
  width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #fff;
    position: absolute;
    bottom: -5px;
    background-color:#f50057;
     left: -5px;
    top: -8px;
}
.MuiDialog-paperWidthLg {
  max-width: 1100px !important;
}
.note-popover.popover{
  z-index: 1600;
}
.comment:last-child {
  height: 100%;
}