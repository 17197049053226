.home {
  background-color: #F5F8F9;
}

.boards-container {
  display: grid;
  grid-gap: 24px 32px;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr) );
}

.board-preview-container {
  background: #FFFFFF;
  box-shadow: 0.775974px 1.55195px 7.75974px rgb(0 0 0 / 6%);
  border-radius: 6px;
  cursor: pointer;
  height: 100%;
  transition: box-shadow 0.3s;
  font-family: DM Sans, sans-serif !important;
}
.board-preview-container:hover {
  box-shadow: 4px 4px 40px rgba(21, 29, 77, 0.2);
}

.board-preview-container:hover .viewBtn {
  background-color: #3F51B6 !important;
  color: #fff !important;
}

.board-details {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.board-initials {
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  border-radius: 100%;
  background: #938AFC;
  width: 40px;
  height: 40px;
  text-transform: uppercase;
}

.board-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #181E23;
  margin: 6px 0px 10px 0px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 48px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.viewBtn {
  background-color: #EBF1FF !important;
  border-radius: 8px !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  color: #202427 !important;
  box-shadow: none !important;
}
.viewBtn .MuiButton-label {
  text-transform: uppercase !important;
}
.viewBtn:hover {
  background-color: #3F51B6 !important;
  color: #fff !important;
}

.searchInput .MuiInputBase-input {
  padding: 14px 14px 14px 0px;
}

.board-preview {
  width: 120px;
  height: 90px;
  padding: 5px;
  background-color: #eee
}

.boardsTopBar {
  background: #FFF;
}

.boardTabs {
  padding: 16px 10px 0px;
  box-shadow: 0px 2px 12px rgb(12 19 61 / 8%);
}

.actionContainer {
  margin-right: 20px;
  float: right;
  display: flex;
  align-items: center;
  padding-top: 10px;
}

.searchBoards {
  width: 250px !important;
  margin-right: 20px !important;
}

.webView {
  display: flex;
}

.mobView {
  display: none;
}

@media only screen and (max-width: 769px) {
  .boardsTopBar {
    display: flex;
    flex-direction: column-reverse;
  }
  .boardTabs {
    padding: 0px;
  }
  .actionContainer {
    flex-direction: column-reverse;
    margin: 0px;
    padding: 12px 10px;
  }
  .searchBoards {
    max-width: 350px !important;
    width: 100% !important;
    margin-right: 0px !important;
    margin-top: 5px !important;
  }
  .webView {
    display: none;
  }
  .mobView {
    display: flex;
  }
  .actionBtnGroup .MuiButton-containedPrimary {
    margin: 0px 8px 8px;
    padding: 6px 10px;
  }
}

@media only screen and (min-width: 420px) and (max-width: 769px) {
  .boardTabsContainer .MuiTabs-flexContainer {
    justify-content: center;
  }
}
